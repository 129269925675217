<template>
  <div v-if="getAllowFullSite" class="main content-page donate-complete">

    <div class="vertical-image"></div>

    <div class="content-page-inner">

        <h1>Thank you for your donation!</h1>

        <template v-if="donationId">
            <p>Your support enables GAP's vision to awaken the followers of Christ to bring cultural transformation and increased Kingdom expansion for generations to come.</p>
            <table>
                <tr><td class="label">Date</td><td>{{ donation.donation_date }}</td></tr>
                <tr><td class="label">Donation Number</td><td>{{ donation.donation_number }}</td></tr>
                <tr><td class="label">Amount</td><td>${{ donation.payment.amount }}</td></tr>
                <tr><td class="label">{{ donation.payment.description }}</td><td>{{ donation.payment.reference_number }}</td></tr>
                <tr><td class="label">Designation</td><td>{{ donation.designation.description }}</td></tr>
                <tr><td class="spacer"></td><td></td></tr>
                <tr><td class="label">Donor Name</td><td>{{ donation.contact.full_name }}</td></tr>
                <tr><td class="label">Address</td><td>{{ donation.contact.address_1 }}</td></tr>
                <tr><td class="label"></td><td>{{ donation.contact.city }}, {{ donation.contact.state }} {{ donation.contact.postal_code }}</td></tr>
                <tr><td class="spacer"></td><td></td></tr>
                <tr><td class="label">Email Address</td><td>{{ donation.contact.email_address }}</td></tr>
                <tr><td class="label">Phone Number</td><td>{{ donation.contact.phone_number }}</td></tr>
                <tr><td class="spacer"></td><td></td></tr>
                <tr v-if="donation.notes"><td class="label">Note</td><td>{{ donation.notes }}</td></tr>
                <tr><td class="spacer"></td><td></td></tr>
            </table>
            <p>We sent a copy of this receipt to your email address {{ donation.contact.email_address }}. Feel free to also print this page for your records.</p>
        </template>

        <template v-else>
            <p>Oops! We couldn't find any info for this donation.</p>
        </template>

    </div>

  </div>
</template>

<script>
import StateSelect from '@/components/StateSelect.vue'
export default {
    name: 'donation-complete',
    data () {
        return {
            donationId: '',
            donation: {}
        }
    },
    created() {
        if (!this.getAllowFullSite) {
            this.$router.push('/coming-soon')
        }
        if (this.$route.query['token']) {
            this.fetchDonationData()
        }
    },
    methods: {
		fetchDonationData(state) {
            this.apiCall({ destination: 'get_donation', data: { token: this.$route.query['token'] } }).then(obj => {
                if (obj.status == 'success') {
                    this.donation = obj.donation
                    this.donationId = obj.donation.donation_id
                } else {
                    this.message = obj.message
                }
            })
		}
    }
}
</script>

<style lang="scss" scoped>
.vertical-image {
    overflow: hidden;
    background-image: url("~@/assets/wind-turbine.png");
    background-size: cover;
    background-position: center bottom;
}
td.spacer { height: 1em; }
td.label { width: 14em; font-size: 0.8em; color: #666; text-align: right; padding: 0 2em 0 0; }
</style>
